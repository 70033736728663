import React, { useMemo, useRef }  from 'react';
import axios from 'axios';
import style from './style.module.scss';
import Login from '../highlineLogin';
import Button from '../UI/button';

const Highline = props => {
    const [user, setUser] = React.useState(null);
    const [list, setList] = React.useState([]);

    React.useEffect(() => {
        const localStorageUser = localStorage.getItem('highline_user');
        if (localStorageUser) {
            const user = JSON.parse(localStorage.getItem('highline_user'));
            setUser(user);
        } else {
            localStorage.removeItem('highline_user');
        }
    }, []);

    React.useEffect(() => {
        if (user) {
            if (list.length === 0) {
                axios.get('https://together-wegrow.com/api/v1/highline_user/users').then((response) => {
                    setList(response.data.sort((a, b) => b.sends - a.sends));
                });
            }
        }
    }, [user]);

    const reload = () => {
        axios.get('https://together-wegrow.com/api/v1/highline_user/users').then((response) => {
            setList(response.data.sort((a, b) => b.sends - a.sends));
        });
    }

    const handleAddRoute = async () => {
        await axios.post('https://together-wegrow.com/api/v1/highline_user/user', { username: user.username });
        const tempList = list.map(item => {
            if (item.username === user.username) {
                return {
                    ...item,
                    sends: item.sends + 1
                };
            } else {
                return { ...item };
            }
        });
        setList(tempList.sort((a, b) => b.sends - a.sends));
    }

    const handleRemoveRoute = async () => {
        await axios.delete(`https://together-wegrow.com/api/v1/highline_user/user?username=${user.username}`);
        const tempList = list.map(item => {
            if (item.username === user.username) {
                return {
                    ...item,
                    sends: item.sends - 1
                };
            } else {
                return { ...item };
            }
        });
        setList(tempList.sort((a, b) => b.sends - a.sends));
    }

    const handleLogout = () => {
        localStorage.setItem('highline_user', '');
        setList([]);
        setUser(null);
    }

    return (
        <div className={style.highline}>
            {!user
                ? <Login handleLoggedIn={userData => setUser(userData)} />
                : <div className={style.highline_wrapper}>
                    <div className={style.highline_heading}>
                        <h3>{`Hi ${user.user}, welcome to TWG Highline Most sends contest and enjoi! Be fair part of the game!`}</h3>
                        <Button title="logout" className={style.highline_heading_logout} onClick={() => handleLogout()} />
                    </div>
                    <div className={style.highline_selection}>
                        <Button title="Add Send" className={style.highline_body_button} onClick={() => handleAddRoute()} />
                        <Button title="Remove Send" className={style.highline_body_button} onClick={() => handleRemoveRoute()} />
                    </div>
                    <div>
                        <div className={style.highline_climber}>
                            <h4>Ranking List</h4>
                            <svg onClick={() => reload()} fill="#000000" height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.645 489.645" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3 c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5 c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8 c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2 C414.856,432.511,548.256,314.811,460.656,132.911z"></path> </g> </g></svg>
                        </div>
                        <div className={style.list}>
                            <div className={style.list_row}>
                                <span className={[style.list_heading, style.list_row_number].join(' ')}>no.</span>
                                <span className={style.list_heading}>Name</span>
                                <span className={style.list_heading}>Sends</span>
                            </div>
                            {list.map((item, index) => <div className={[style.list_row, item.username === user.username ? style.logedinuser : ''].join(' ')}>
                                    <span className={style.list_row_number}>{index + 1}</span>
                                    <span>{item.firstname} {item.lastname}</span>
                                    <span>{item.sends}</span>
                            </div>)}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
export default Highline;
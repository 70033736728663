import React from 'react';
import axios from 'axios';
import style from './style.module.scss';
import Button from '../UI/button';

const Login = props => {
    const [login, setLogin] = React.useState(false);

    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [username, setUsername] = React.useState('');

    const [loginErrorMsg, setLoginErrorMsg] = React.useState(null);
    const [registerErrorMsg, setRegisterErrorMsg] = React.useState(null);

    const handleLogin = () => {
        if (login) {
            axios.post('https://together-wegrow.com/api/v1/highline_user/login', {
                username
            }).then((response) => {
                setLoginErrorMsg(null);
                props.handleLoggedIn({ user: `${response.data.firstname} ${response.data.lastname}`, username});
                localStorage.setItem('highline_user', JSON.stringify({ user: `${response.data.firstname} ${response.data.lastname}`, username}));
            }).catch(() =>
                setLoginErrorMsg('User does not exist')
            );
        } else {
            axios.post('https://together-wegrow.com/api/v1/highline_user/register', {
                firstname,
                lastname,
                username
            }).then((response) => {
                    setRegisterErrorMsg(null);
                    props.handleLoggedIn({ user: `${response.data.firstname} ${response.data.lastname}`, username});
                    localStorage.setItem('highline_user', JSON.stringify({ user: `${response.data.firstname} ${response.data.lastname}`, username}));
            }).catch(() =>
                setRegisterErrorMsg('Username already taken!')
            );
        }
    };

    return (
        <div className={style.login_wrapper}>
            <div className={style.login_wrapper_selection}>
                <span className={login ? style.active : ''} onClick={() => setLogin(true)} >Login</span>
                <span className={!login ? style.active : ''} onClick={() => setLogin(false)}>Register</span>
            </div>
            {login
                ? <div className={style.login}>
                    <span>LOGIN</span>
                    <h4>Enter your username</h4>
                    <p>Username</p>
                    <input className={style.registration_input} type='text' placeholder='Enter your username' onChange={e => setUsername(e.target.value)} />
                    {loginErrorMsg && <span className={style.registration_input_errormsg}>{loginErrorMsg}</span>}
                </div>
                : <div className={style.login}>
                    <span>REGISTER!</span>
                    <h4>Enter your personal info! Your username will be used for login, choose wisely!</h4>
                    <div className={[style.registration, "registration"].join(' ')}>
                        <p>Firstname</p>
                        <input className={style.registration_input} type='text' placeholder='Enter your firstname' onChange={e => setFirstname(e.target.value)}/>
                        <p>Lastname</p>
                        <input className={style.registration_input} type='text' placeholder='Enter your lastname' onChange={e => setLastname(e.target.value)}/>
                        <p>Username</p>
                        <input className={style.registration_input} type='text' placeholder='Enter your username' onChange={e => setUsername(e.target.value)} />
                    </div>
                    {registerErrorMsg && <span className={style.registration_input_errormsg}>{registerErrorMsg}</span>}
                </div>}
            <Button title={login ? "Login" : "Register"} onClick={() => handleLogin()}/>
        </div>
    );
};
export default Login;